<template>
	<view>
		<u-navbar title="修改手机号" title-color="#00000" :background="background">
		</u-navbar>

		<view class="u-p-30">
			<view class="u-font-40">设置新的手机号</view>
		</view>

		<view class="u-p-l-50">
			<u-form :model="form" ref="uForm" label-width="80px">
				<u-form-item label="手机号">
					<u-input v-model="form.phone" placeholder="请输入本人手机号码" />
				</u-form-item>
				<view class="">
					<u-field v-model="form.vcode" label="验证码" placeholder="请填写验证码">
						<u-button size="mini" slot="right" type="warning" @click="getCode">{{codeText}}
						</u-button>
					</u-field>
					<u-verification-code ref="uCode" @change="codeChange"></u-verification-code>
				</view>
			</u-form>
		</view>
		<view class="u-m-t-80 u-padding-30">
			<u-button type="primary" @click="save" :loading="saveLoading"> 确 认 </u-button>
		</view>
	</view>
</template>

<script>
	import Vue from 'vue';
	import {
		Form,
		Field,
		Button,
		Checkbox,
		Col,
		Row,
		Toast
	} from 'vant';

	Vue.use(Form);
	Vue.use(Field);
	Vue.use(Button);
	Vue.use(Checkbox);
	Vue.use(Col);
	Vue.use(Row);
	export default {
		data() {
			return {
				background: {
					backgroundColor: '#FBDF3E',
				},
				form: {

				},
				code: '',
				codeText: '',
				saveLoading: false
			}
		},
		methods: {
			async save() {
				const params = JSON.parse(JSON.stringify(this.form));
				this.saveLoading = true
				let {
					message,
					status
				} = await this.$axios('user/updateuser', params)
				this.saveLoading = false;
				if (status === 20000) {
					this.$u.toast('修改手机号成功');
					setTimeout(() => {
						this.$router.push('/login');
					})
				} else {
					this.$u.toast(message || '修改手机号失败');
				}
			},
			codeChange(text) {
				this.codeText = text;
			},
			async getCode() {
				if (!this.form.phone) {
					this.$u.toast('请输入手机号码');
					return false
				}
				if (this.$refs.uCode.canGetCode) {
					// 模拟向后端请求验证码
					Toast.loading({
						message: '正在获取验证码...',
						forbidClick: true,
					});
					const params = {
						phone: this.form.phone
					}

					let {
						message
					} = await this.$axios('sendCode', params)

					this.$u.toast(message)
					Toast.clear()
					// 通知验证码组件内部开始倒计时
					this.$refs.uCode.start();
				} else {
					this.$u.toast('倒计时结束后再发送');
				}
			}
		}
	}
</script>

<style scoped>
	page {
		background-color: #FFFFFF;
	}
</style>
